// This file is imported after all core/plugin stylesheets.
// This allows you to overwrite any styling you want.
// Keep in mind variables and mixins defined here will have no effect on earlier imported styling.
// If you want that, you should define them in includes.scss.
// Anything defined in the includes files (from core and theme) is available for use.

skr-app {
    skr-brand-logo {
      // create the diagonal line
      .menu-icon {
        background-color: $white;
        transform:skewX(23deg);
        transform-origin: 100% 100%;
        position: absolute;
        padding-left: 10px;
        left: 0;
        top: 0;
      }
  
      .menu-icon img {
        height: 65px;
        padding: 6px 22px;
        transform:skewX(-23deg);
      }
    }
  }